import React from 'react'
// import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import VideoPlaceHolder from './../images/builder-gif.gif'
import TagLine from '../components/tagline'
import CtaBlue from '../components/cta-blue'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'
import DiscountBanner from '../components/discount-banner'
import DiscountTerms from '../components/discount-terms'

class IndexPage extends React.Component {
  render () {
    return (<Layout>
      <SEO
        title='How It Works'
        keywords={[`web hosting`, `domain names`, `web site`, `search engine optimization`, `hosting`, `servers`]}
        pageType={'product_page'}
        flowType={'none'} />
      <DiscountBanner />
      <section className='bg-lightgray'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8 page-heading'>
              <h2>How it works</h2>
              <p className='body-large black-60'>Answer a few quick questions. Then our intelligent builder chooses the right layout, pulls in beautiful images, and even starts your content.</p>
            </div>
          </div>
        </div>
      </section>
      <section className='how-it-works'>
        <div className='container section-padding'>
          <TagLine title={'Check out how easy our builder is to use for yourself'} tagSize={'h2'} />
          <div className='row'>
            <div className='col-12 d-flex justify-content-center'>
              <img src={VideoPlaceHolder} className='video-placeholder' alt='Animation of Builder Functionality' />
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-10 body-large mx-auto'>
              <CtaBlue
                eventName='start_onboarding_how_it_works'
                title={'Try it first.  No commitment necessary.'}
                buttonTitle={'Try it for free'}
                buttonLink={process.env.GATSBY_SIGNUP_SITE_URL}
                currentPage={'how-it-works'}
                dataElementLocation={DataElementLocations.BODY}
              />
            </div>
          </div>
        </div>
        <DiscountTerms />
      </section>
    </Layout>)
  }
}

export default IndexPage
